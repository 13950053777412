import axios from 'axios';
import { decryptWithAESJWT } from './crypto';

export async function authenticate(username, password) {
    try {
        const credentials = await login(username, password);
        return credentials;
    } catch (error) {
        console.error('Authentication failed:', error);
    }
};

async function getToken() {
    const url = `${process.env.REACT_APP_JWT_HOST}/api/v2/getToken`;
    const header = {
        'Authorization': "Basic " + process.env.REACT_APP_JWT_AUTH
    };
    try {
        const response = await axios.get(url, { headers: header });
        const responseData = response.data;
        const json = JSON.parse(decryptWithAESJWT(responseData.data));
        const token = json.outToken;
        return token;
    } catch (error) {
        console.error('Failed to get token:', error);
        throw error;
    }
}

async function login(username, password) {
    try {
        let token = '';
        if (process.env.REACT_APP_ENVIRONMENT === 'production') {
            token = await getToken();
        } else {
            token = 'dev-token';
        }
        const url = `${process.env.REACT_APP_API_HOST}/v1/cms/ms-teams/authenticate`;
        const header = {
            'Authorization': "Bearer " + token
        };
        const body = {
            "username": username,
            "password": password
        };
        const formData = new FormData();
        formData.append("body", JSON.stringify(body));
        const response = await axios.post(url, formData, { headers: header });
        const credentials = response.data.data.credential_key;
        return credentials;
    } catch (error) {
        console.error('Login failed:', error);
        throw error;
    }
}

